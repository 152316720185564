import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import dayjs from 'dayjs'
import Helmet from 'react-helmet'

// CSS
import '@wordpress/block-library/build-style/style.css'
import './post.css'

// Our Components
import Layout from '../../components/layout'
import StructuredData from '../../components/structured-data'
import SEO from '../../components/seo'

const PostTemplate = props => {
  const {
    data: {
      wordpress: { post },
    },
    pageContext,
  } = props

  const { categories, tags } = post

  return (
    <Layout flush={true}>
      <SEO
        createdAt={post.date}
        updatedAt={post.modified}
        slug={pageContext.uri}
        meta={post.seo}
        pageType="article"
      />
      <StructuredData pageType="article" post={post} />
      <Helmet titleTemplate={`%s`} />
      <div className="relative pt-10 pb-20 lg:pt-16 bg-purple-900 text-purple-100 shadow">
        <div className="container max-w-5xl text-center">
          <h1
            className="mb-6 lg:mb-10 text-2xl md:text-4xl xl:text-5xl md:leading-tight"
            dangerouslySetInnerHTML={{ __html: post.title }}
          />
          <div className="text-sm">
            <time dateTime={post.date}>
              {dayjs(post.date).format('MMMM D, YYYY')}
            </time>{' '}
            {post.author && (
              <span className="ml-1">
                |<span className="ml-2">{post.author.name}</span>
              </span>
            )}
            {categories.nodes[0] && (
              <div className="mt-2 underline">{categories.nodes[0].name}</div>
            )}
            <div
              className="absolute"
              style={{
                bottom: `-48px`,
                left: `50%`,
                transform: `translateX(-50%)`,
              }}
            >
              {post.author.avatar.imageFile && (
                <Img
                  className="rounded-full border-4 border-white md:border-purple-200"
                  fixed={post.author.avatar.imageFile.childImageSharp.fixed}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="md:bg-purple-200 py-12 md:py-16">
        <div className="container pt-4 md:p-8 md:bg-purple-100 md:rounded md:border border-purple-300 md:shadow-sm max-w-5xl">
          <div
            className="entry md:text-xl"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          {tags.nodes[0] && (
            <div className="pt-2 mb-6 text-sm text-gray-700">
              Tagged with:{' '}
              {tags.nodes.map((tag, i, array) => (
                <span className="text-blue-600" key={tag.name}>
                  {tag.name}
                  {i < array.length - 1 ? ', ' : null}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.shape({
    wordpress: PropTypes.shape({
      post: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        uri: PropTypes.string,
        date: PropTypes.string,
        modified: PropTypes.string,
        featuredImage: PropTypes.shape({
          sourceUrl: PropTypes.string,
          imageFile: PropTypes.object,
        }),
        author: PropTypes.shape({
          name: PropTypes.string,

          avatar: PropTypes.shape({
            url: PropTypes.string,
            imageFile: PropTypes.object,
          }),
        }),
        seo: PropTypes.object,
        categories: PropTypes.shape({
          nodes: PropTypes.array,
        }),
        tags: PropTypes.shape({
          nodes: PropTypes.array,
        }),
      }),
    }),
  }),
  pageContext: PropTypes.shape({
    id: PropTypes.string,
    uri: PropTypes.string,
  }),
}

export const pageQuery = graphql`
  query GetPost($id: ID!) {
    wordpress {
      post(id: $id) {
        title
        content
        uri
        slug
        date
        modified
        featuredImage {
          sourceUrl
          imageFile {
            publicURL
          }
        }
        author {
          name
          avatar {
            url
            imageFile {
              childImageSharp {
                fixed(width: 96, height: 96, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
        categories(where: { exclude: "1" }) {
          nodes {
            name
          }
        }
        seo {
          ...seoFields
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`

export default PostTemplate
